import { call, put, takeLatest } from 'redux-saga/effects';
import { axiosApiInstance, config } from '../../../custom-configs';
import { catchResponseMessages } from '../../../utils/methods';
import {
  createAPSFailure,
  createAPSRequest,
  createAPSSuccess,
  getAPSFailure,
  getAPSRequest,
  getAPSSelectOptionsFailure,
  getAPSSelectOptionsRequest,
  getAPSSelectOptionsSuccess,
  getAPSSuccess,
  getAPSWeeklyReportsFailure,
  getAPSWeeklyReportsRequest,
  getAPSWeeklyReportsSuccess,
  getCredentialsFailure,
  getCredentialsRequest,
  getCredentialsSuccess,
  gettingAPSFailure,
  gettingAPSRequest,
  gettingAPSSuccess,
  gteApsNewReportFailure,
  gteApsNewReportRequest,
  gteApsNewReportSuccess,
  updateAPSFailure,
  updateAPSRequest,
  updateAPSSuccess,
  updateCarrierCredentialsFailure,
  updateCarrierCredentialsRequest,
  updateCarrierCredentialsSuccess,
} from './actions';

const URL = `${config.TENANT}/settings`;

export function* createAPS({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create-aps`, data)
    );

    if (response?.status === 200) {
      yield put(createAPSSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for create APS, error', e);
    if (e?.response?.data) {
      yield put(createAPSFailure(catchResponseMessages(e)));
    }
  }
}

export function* getAPS({ payload = '' }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/all-aps${payload}`)
    );

    if (response?.status === 200) {
      yield put(gettingAPSSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getAPS, error', e);
    if (e?.response?.data) {
      yield put(gettingAPSFailure(catchResponseMessages(e)));
    }
  }
}

export function* updateAPS({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/update-aps/${payload.id}`, payload)
    );

    if (response?.status === 200) {
      yield put(updateAPSSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateAPS, error', e);
    if (e?.response?.data) {
      yield put(updateAPSFailure(catchResponseMessages(e)));
    }
  }
}

export function* updateCredentials({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/update-credentials`, payload)
    );

    if (response?.status === 200) {
      yield put(updateCarrierCredentialsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateCredentials, error', e);
    if (e?.response?.data) {
      yield put(updateCarrierCredentialsFailure(catchResponseMessages(e)));
    }
  }
}

export function* getCredentials() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/credentials`)
    );

    if (response?.status === 200) {
      yield put(getCredentialsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getCredentials, error', e);
    if (e?.response?.data) {
      yield put(getCredentialsFailure(catchResponseMessages(e)));
    }
  }
}

export function* getAPSSelect({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/aps-select-options${payload}`)
    );

    if (response?.status === 200) {
      yield put(getAPSSelectOptionsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getAPSSelect, error', e);
    if (e?.response?.data) {
      yield put(getAPSSelectOptionsFailure(catchResponseMessages(e)));
    }
  }
}

export function* getAPSById({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/aps/${payload.id}${payload.query}`)
    );

    if (response?.status === 200) {
      yield put(getAPSSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getAPSSelect, error', e);
    if (e?.response?.data) {
      yield put(getAPSFailure(catchResponseMessages(e)));
    }
  }
}

export function* getAPSReportsWeekly({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/all-aps-weekly-reports${payload}`)
    );

    if (response?.status === 200) {
      yield put(getAPSWeeklyReportsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getAPSWeeklyReports, error', e);
    if (e?.response?.data) {
      yield put(getAPSWeeklyReportsFailure(catchResponseMessages(e)));
    }
  }
}

export function* getApsReport({ payload = '' }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/aps-report${payload}`)
    );

    if (response?.status === 200) {
      yield put(gteApsNewReportSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getApsReport, error', e);
    if (e?.response?.data) {
      yield put(gteApsNewReportFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(createAPSRequest, createAPS);
  yield takeLatest(gettingAPSRequest, getAPS);
  yield takeLatest(updateAPSRequest, updateAPS);
  yield takeLatest(updateCarrierCredentialsRequest, updateCredentials);
  yield takeLatest(getCredentialsRequest, getCredentials);
  yield takeLatest(getAPSSelectOptionsRequest, getAPSSelect);
  yield takeLatest(getAPSRequest, getAPSById);
  yield takeLatest(getAPSWeeklyReportsRequest, getAPSReportsWeekly);
  yield takeLatest(gteApsNewReportRequest, getApsReport);
}
