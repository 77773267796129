import {
  createAPSRequest,
  createAPSSuccess,
  createAPSFailure,
  gettingAPSRequest,
  gettingAPSSuccess,
  gettingAPSFailure,
  updateAPSRequest,
  updateAPSSuccess,
  updateAPSFailure,
  updateCarrierCredentialsRequest,
  updateCarrierCredentialsSuccess,
  updateCarrierCredentialsFailure,
  getCredentialsRequest,
  getCredentialsSuccess,
  getCredentialsFailure,
  getAPSSelectOptionsRequest,
  getAPSSelectOptionsSuccess,
  getAPSSelectOptionsFailure,
  getAPSRequest,
  getAPSSuccess,
  getAPSFailure,
  getAPSWeeklyReportsRequest,
  getAPSWeeklyReportsSuccess,
  getAPSWeeklyReportsFailure,
  gteApsNewReportRequest,
  gteApsNewReportSuccess,
  gteApsNewReportFailure,
} from './actions';
import { handleActions } from 'redux-actions';

const initialState = {
  isCreateAPS: false,
  isCreateAPSSuccess: false,
  isCreateAPSFailure: false,
  isGettingAPS: true,
  isGettingAPSSuccess: false,
  isGettingAPSFailure: false,
  allAPS: [],
  APSCount: 0,
  isUpdateAPS: false,
  isUpdateAPSSuccess: false,
  isUpdateAPSFailure: false,
  errorMessage: '',
  isUpdatingCredentials: false,
  isUpdatingCredentialsSuccess: false,
  isUpdatingCredentialsFailure: false,
  successMessage: '',
  credentials: {},
  isGettingCredentials: true,
  isGettingCredentialsFailure: false,
  isGettingAPSSelectOptions: false,
  apsSelectOptions: [],
  isGettingAPSByIdRequest: true,
  isGettingAPSByIdSuccess: false,
  isGettingAPSByIdFailure: false,
  aps: {},
  isGettingAPSWeeklyReportsRequest: true,
  isGettingAPSWeeklyReportsSuccess: false,
  isGettingAPSWeeklyReportsFailure: false,
  apsReports: [],
  apsReportsCount: 0,
  updated: '',
  lastSubmittedDate: '',
  isGettingNewReport: true,
  newReports: [],
};

const reducer = handleActions(
  {
    [createAPSRequest]: (state) => ({
      ...state,
      isCreateAPS: true,
      isCreateAPSSuccess: false,
      isCreateAPSFailure: false,
    }),
    [createAPSSuccess]: (state, { payload }) => ({
      ...state,
      isCreateAPS: false,
      isCreateAPSSuccess: true,
      isCreateAPSFailure: false,
      allAPS: [...state.allAPS, payload.data],
    }),
    [createAPSFailure]: (state, { payload }) => ({
      ...state,
      isCreateAPS: false,
      isCreateAPSSuccess: false,
      isCreateAPSFailure: true,
      errorMessage: payload,
    }),
    // Get APS
    [gettingAPSRequest]: (state) => ({
      ...state,
      isGettingAPS: true,
      isGettingAPSSuccess: false,
      isGettingAPSFailure: false,
      APSCount: 0,
    }),
    [gettingAPSSuccess]: (state, { payload }) => ({
      ...state,
      isGettingAPS: false,
      isGettingAPSSuccess: true,
      isGettingAPSFailure: false,
      allAPS: payload.data.rows,
      APSCount: payload.data.count,
      updated: payload.data.updated,
      lastSubmittedDate: payload.data.lastSubmittedDate,
    }),
    [gettingAPSFailure]: (state) => ({
      ...state,
      isGettingAPS: false,
      isGettingAPSSuccess: false,
      isGettingAPSFailure: true,
    }),
    //  Update APS
    [updateAPSRequest]: (state) => ({
      ...state,
      isUpdateAPS: true,
      isUpdateAPSSuccess: false,
      isUpdateAPSFailure: false,
    }),
    [updateAPSSuccess]: (state, { payload }) => {
      const APSListCopy = [...state.allAPS];
      const index = APSListCopy.findIndex((i) => i.id === payload.data.id);
      if (index !== -1) {
        APSListCopy[index] = payload.data;
      }
      return {
        ...state,
        isUpdateAPS: false,
        isUpdateAPSSuccess: true,
        isUpdateAPSFailure: false,
        allAPS: APSListCopy,
      };
    },
    [updateAPSFailure]: (state) => ({
      ...state,
      isUpdateAPS: false,
      isUpdateAPSSuccess: false,
      isUpdateAPSFailure: true,
    }),
    [updateCarrierCredentialsRequest]: (state) => ({
      ...state,
      isUpdatingCredentials: true,
      isUpdatingCredentialsSuccess: false,
      isUpdatingCredentialsFailure: false,
    }),
    [updateCarrierCredentialsSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingCredentials: false,
      isUpdatingCredentialsSuccess: true,
      successMessage: payload.message,
      credentials: payload.data,
    }),
    [updateCarrierCredentialsFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingCredentials: false,
      isUpdatingCredentialsFailure: true,
      errorMessage: payload,
    }),
    [getCredentialsRequest]: (state) => ({
      ...state,
      isGettingCredentials: true,
      isGettingCredentialsFailure: false,
    }),
    [getCredentialsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingCredentials: false,
      credentials: payload.data,
    }),
    [getCredentialsFailure]: (state, { payload }) => ({
      ...state,
      isGettingCredentials: false,
      isGettingCredentialsFailure: true,
      errorMessage: payload,
    }),
    [getAPSSelectOptionsRequest]: (state) => ({
      ...state,
      isGettingAPSSelectOptions: true,
    }),
    [getAPSSelectOptionsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingAPSSelectOptions: false,
      apsSelectOptions: payload.data,
      successMessage: payload.message,
    }),
    [getAPSSelectOptionsFailure]: (state, { payload }) => ({
      ...state,
      isGettingAPSSelectOptions: false,
      errorMessage: payload,
    }),
    [getAPSRequest]: (state) => ({
      ...state,
      isGettingAPSByIdRequest: true,
    }),
    [getAPSSuccess]: (state, { payload }) => ({
      ...state,
      isGettingAPSByIdRequest: false,
      isGettingAPSByIdSuccess: true,
      aps: payload.data,
      successMessage: payload.message,
    }),
    [getAPSFailure]: (state, { payload }) => ({
      ...state,
      isGettingAPSByIdFailure: false,
      errorMessage: payload,
    }),
    [getAPSWeeklyReportsRequest]: (state) => ({
      ...state,
      isGettingAPSWeeklyReportsRequest: true,
    }),
    [getAPSWeeklyReportsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingAPSWeeklyReportsRequest: false,
      isGettingAPSWeeklyReportsSuccess: true,
      apsReports: payload.data.rows,
      apsReportsCount: payload.data.count,
      successMessage: payload.message,
    }),
    [getAPSWeeklyReportsFailure]: (state, { payload }) => ({
      ...state,
      isGettingAPSWeeklyReportsFailure: false,
      errorMessage: payload,
    }),
    [gteApsNewReportRequest]: (state) => ({
      ...state,
      isGettingNewReport: true,
    }),
    [gteApsNewReportSuccess]: (state, { payload }) => ({
      ...state,
      isGettingNewReport: false,
      newReports: payload.data,
      successMessage: payload.message,
    }),
    [gteApsNewReportFailure]: (state, { payload }) => ({
      ...state,
      isGettingNewReport: false,
      errorMessage: payload,
    }),
  },
  initialState
);

export default reducer;
