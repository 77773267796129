import { createAction } from 'redux-actions';

export const createAPSRequest = createAction('CREATE_APS_REQUEST');
export const createAPSSuccess = createAction('CREATE_APS_SUCCESS');
export const createAPSFailure = createAction('CREATE_APS_FAILURE');

export const gettingAPSRequest = createAction('CREATE_GETTING_REQUEST');
export const gettingAPSSuccess = createAction('CREATE_GETTING_SUCCESS');
export const gettingAPSFailure = createAction('CREATE_GETTING_FAILURE');

export const updateAPSRequest = createAction('UPDATE_APS_REQUEST');
export const updateAPSSuccess = createAction('UPDATE_APS_SUCCESS');
export const updateAPSFailure = createAction('UPDATE_APS_FAILURE');

export const updateCarrierCredentialsRequest = createAction(
  'UPDATE_CARRIER_CREDENTIALS_REQUEST'
);
export const updateCarrierCredentialsSuccess = createAction(
  'UPDATE_CARRIER_CREDENTIALS_SUCCESS'
);
export const updateCarrierCredentialsFailure = createAction(
  'UPDATE_CARRIER_CREDENTIALS_FAILURE'
);

export const getCredentialsRequest = createAction('GET_CREDENTIALS_REQUEST');
export const getCredentialsSuccess = createAction('GET_CREDENTIALS_SUCCESS');
export const getCredentialsFailure = createAction('GET_CREDENTIALS_FAILURE');

export const getAPSSelectOptionsRequest = createAction(
  'GET_APS_SELECT_OPTIONS_REQUEST'
);
export const getAPSSelectOptionsSuccess = createAction(
  'GET_APS_SELECT_OPTIONS_SUCCESS'
);
export const getAPSSelectOptionsFailure = createAction(
  'GET_APS_SELECT_OPTIONS_FAILURE'
);
export const getAPSRequest = createAction('GET_APS_REQUEST');
export const getAPSSuccess = createAction('GET_APS_SUCCESS');
export const getAPSFailure = createAction('GET_APS_FAILURE');

export const getAPSWeeklyReportsRequest = createAction(
  'GET_APS_WEEKLY_REPORTS_REQUEST'
);
export const getAPSWeeklyReportsSuccess = createAction(
  'GET_APS_WEEKLY_REPORTS_SUCCESS'
);
export const getAPSWeeklyReportsFailure = createAction(
  'GET_APS_WEEKLY_REPORTS_FAILURE'
);

export const gteApsNewReportRequest = createAction(
  'GET_APS_NEW_REPORT_REQUEST'
);
export const gteApsNewReportSuccess = createAction(
  'GET_APS_NEW_REPORT_SUCCESS'
);
export const gteApsNewReportFailure = createAction(
  'GET_APS_NEW_REPORT_FAILURE'
);
